/**
 * = Accordions
 */

 .accordion-button {
    text-align: left;

    .btn-check:focus + .btn, .btn:focus {
        outline: 0;
        box-shadow: none;
    }
    
    &:hover{
        background-color: $gray-200;
    }
    &::after {
        background-image: url("../../../assets/tv/icons-24-px-caret-down-dark@2x.png")!important;
        width: 2rem!important;
        height: 2rem!important;
        background-size: 2rem!important;
    }
}


// Flush accordion items
//
// Remove borders and border-radius to keep accordion items edge-to-edge.

.accordion-flush {
    .accordion-button {
        background-color: transparent;
        &:not(.collapsed) {
            color: $secondary;
        }
        &:focus {
            border-color: $gray-300;
            box-shadow: none;
        }
    }
    .accordion-collapse {
        border: 0;
    }
}


